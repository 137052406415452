import { ILoadingScreen } from "@babylonjs/core";

export const timeout = async (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const isMobile = /Android|BlackBerry|iPod|iPhone|webOS/i.test(
  navigator.userAgent,
);

/**
 * A dummy loading screen that does nothing and has no UI.
 */
export class BabylonLoadingScreen implements ILoadingScreen {
  public loadingUIBackgroundColor: string = "";
  public loadingUIText: string = "";
  public displayLoadingUI() {}
  public hideLoadingUI() {}
}
