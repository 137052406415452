import React, { CSSProperties } from "react";
import { ModernTheme, Theme } from "./Theme";
import { Link } from "react-router-dom";

const ModernButtonStyles = {
  [ModernTheme.Red]: "bg-jgu-red text-jgu-gray-20",
  [ModernTheme.Blue]: "bg-jgu-blue text-jgu-gray-20",
  [ModernTheme.DarkBlue]: "bg-jgu-dark-blue text-jgu-gray-20",
  [ModernTheme.Purple]: "bg-jgu-purple text-jgu-gray-20",
  [ModernTheme.Green]: "bg-jgu-green text-jgu-gray-20",
  [ModernTheme.Orange]: "bg-jgu-orange text-jgu-gray-20",
  [ModernTheme.Black]: "bg-jgu-black text-jgu-gray-20",
  [ModernTheme.Gray]: "bg-jgu-gray text-jgu-gray-20",
  [ModernTheme.Yellow]: "bg-jgu-yellow text-jgu-gray-20",
} as { [key in ModernTheme]: string };

export const Button = (props: {
  className?: string;
  style?: CSSProperties;
  children: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  theme?: Theme | ModernTheme;
  buttonRef?: React.RefObject<HTMLButtonElement>;
  sharp?: boolean;
}) => {
  return (
    <button
      ref={props.buttonRef}
      onClick={props.onClick}
      className={[
        props.theme && (props.theme as ModernTheme)
          ? ModernButtonStyles[props.theme as ModernTheme]
          : ModernButtonStyles[ModernTheme.Red],
        "p-2 pl-3 pr-3 transition-all hover:scale-110 disabled:opacity-50 disabled:hover:scale-100 disabled:hover:shadow-none",
        props.theme == "transparent" || props.theme == ModernTheme.Transparent
          ? ""
          : "shadow-sm hover:shadow-xl",
        props.sharp ? "rounded-xl" : "rounded-full",
        props.className,
      ].join(" ")}
      disabled={props.disabled}
      style={props.style}
    >
      {props.children}
    </button>
  );
};

export const ButtonLink = (props: {
  to: string;
  children: React.ReactNode;
  className?: string;
  style?: CSSProperties;
  theme?: ModernTheme | Theme;
  target?: string;
  routerLinkTarget?: string;
  sharp?: boolean;
}) => {
  return (
    <Link
      to={props.to}
      target={props.target}
      className={[
        props.theme && (props.theme as ModernTheme)
          ? ModernButtonStyles[props.theme as ModernTheme]
          : ModernButtonStyles[ModernTheme.Red],
        "p-2 pl-3 pr-3 transition-all hover:scale-110 disabled:opacity-50 disabled:hover:scale-100 disabled:hover:shadow-none",
        props.theme == "transparent" || props.theme == ModernTheme.Transparent
          ? ""
          : "shadow-sm hover:shadow-xl",
        props.sharp ? "rounded-none" : "rounded-full",
        props.className,
      ].join(" ")}
      style={props.style}
    >
      {props.children}
    </Link>
  );
};
