import { createContext, useContext, useEffect, useState } from "react";
import { T } from "../components/ui/T";

type UserData = {
  user?: {
    name: string;
    email: string;
    picture: string;
  };
  token?: string;
  authorized?: boolean;
  success: boolean;
};

type AuthData = {
  data: UserData;
  ensureLogin: () => Promise<void>;
};

const AuthContext = createContext({} as AuthData);

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [userData, setUserData] = useState<UserData>({
    success: false,
  });

  useEffect(() => {
    // check if user is logged in
    (async () => {
      const res = await fetch("/api/user");
      console.log(await res.text());
      const json = await res.json();
      if (json.success == false) {
        setUserData({ success: false });
      } else {
        setUserData(json);
      }
    })();
  }, []);

  return (
    <AuthContext.Provider
      value={{
        data: userData,
        ensureLogin: async () => {
          // check if user is logged in if not redirect to login page
          const res = await fetch("/api/user");
          const json = await res.json();
          if (json.success == false) {
            window.location.replace(
              "/api/login?rurl=" + encodeURIComponent(window.location.hostname),
            );
          } else {
            setUserData(json);
          }
        },
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};

export const RequireAuth = (props: { children: React.ReactNode }) => {
  const auth = useAuthContext();

  useEffect(() => {
    auth.ensureLogin();
  }, []);

  if (auth.data.success && auth.data.authorized) {
    return props.children;
  } else {
    return (
      <div className="flex h-full w-full flex-col items-center justify-center gap-2 py-96">
        {auth.data.success ? (
          <>
            <i className="bi bi-exclamation-triangle text-8xl text-jgu-red" />
            <p className="text-jgu-gray">
              <T
                de="Sie haben keine Berechtigung, diese Seite zu sehen."
                en="You are not authorized to view this page."
              />
            </p>
          </>
        ) : (
          <>
            <i className="bi bi-arrow-repeat animate-spin text-8xl text-jgu-gray" />
            <p className="text-jgu-gray">
              <T de="Überprüfe Anmeldung..." en="Checking login..." />
            </p>
          </>
        )}
      </div>
    );
  }
};
