export type Theme =
  | "primary"
  | "secondary"
  | "success"
  | "warning"
  | "info"
  | "light"
  | "accent"
  | "transparent";
export enum ModernTheme {
  Red,
  Blue,
  DarkBlue,
  Purple,
  Green,
  Orange,
  Black,
  Gray,
  Yellow,
  Transparent,
}
