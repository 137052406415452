export const TimelineSpeeds = {
  PICK_TIMELINE: 1000,
};

export const LayerNames = {
  HIGHLIGHT_LAYER_NAME: "highlightlayer",
  GLOW_LAYER_NAME: "glowlayer",
};

export const JGUHex = "#C1002A";
