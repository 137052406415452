import * as React from "react";
import Cookies from "js-cookie";

type LikeProps = {
  value?: number;
  identifier: string;
  small?: boolean;
  noRed?: boolean;
};

export const Like = (props: LikeProps) => {
  let [incremented, setIncremented] = React.useState(
    Cookies.get(props.identifier) == "voted" ? true : false,
  );
  let [value, setValue] = React.useState(props.value);

  const increment = () => {
    if (!incremented) {
      fetch("/api/counter/hit?key=" + encodeURIComponent(props.identifier))
        .then((res) => res.json())
        .then((data) => {
          Cookies.set(props.identifier, "voted", {
            expires: 365 * 2,
          });
          setValue(data["value"]);
        });
    }
    setIncremented(true);
  };

  React.useEffect(() => {
    if (props.value === undefined) {
      fetch("/api/counter/get?key=" + encodeURIComponent(props.identifier))
        .then((res) => res.json())
        .then((data) => {
          setValue(data["value"]);
        });
    }
  }, []);

  return (
    <div
      className={
        "w-100 flex flex-row items-center justify-end gap-1 " +
        (props.small ? "text-xl" : "p-3 text-2xl")
      }
    >
      <button
        type="button"
        onClick={increment}
        className={
          "border-0 bg-none p-2 " + (incremented ? "animate-pulse" : "")
        }
      >
        <i
          className={[
            props.noRed ? "" : "text-jgu-red",
            incremented
              ? "bi-heart-fill cursor-default"
              : "bi-heart cursor-pointer",
          ].join(" ")}
        ></i>
      </button>
      <div className="cursor-default">{value}</div>
    </div>
  );
};
