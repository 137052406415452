import { ReactNode } from "react";
import { useLitteraMethods } from "@assembless/react-littera";

export const T = (props: {
  de: ReactNode;
  en: ReactNode;
  grayOut?: boolean;
  padding?: boolean;
}) => {
  const methods = useLitteraMethods();
  const opacity = props.grayOut ? 0.25 : 1;
  const classes = props.padding ? "mt-2 mb-2" : "";

  return (
    <span className={classes} style={{ opacity }}>
      {methods.locale == "de_DE" ? props.de : props.en}
    </span>
  );
};
